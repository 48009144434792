import React from 'react';
import {Source, Layer} from 'react-map-gl';
import type {CircleLayer} from 'react-map-gl';
import * as turf from "@turf/turf";
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { Feature, MultiPolygon, Polygon } from '@turf/turf';

interface StationCircleLayerProps {
    stations : any[];
}

const StationCircleLayerFSR3: React.FC<StationCircleLayerProps> = (props) => {

    var mergedCircles: Feature<Polygon | MultiPolygon, GeoJsonProperties> = {
      type: "Feature",
      geometry:  null as unknown as Polygon | MultiPolygon,
      properties: {}
    };

    try{
      var radius = 850;
      var circles = props.stations.map((station) => {
        var center = [station.long, station.lat];
        var options = {steps: 30, units: 'meters' as turf.Units, properties : {}}
        return turf.circle(center, radius, options);
      })
      
      var mergedCircles = turf.union(circles[0], circles[1]) as unknown as Feature<Polygon | MultiPolygon, GeoJsonProperties>;
      for (var i = 2; i < circles.length; i++) {
        mergedCircles = turf.union(mergedCircles, circles[i]) as unknown as Feature<Polygon | MultiPolygon, GeoJsonProperties>;
      }
      
    }
    catch(err){
      console.log('Error in StationCircleLayerFSR3', err);
    }
    
    
    
    return (        
        <Source id={`far-3-circle-source`} type="geojson" data={mergedCircles}>
          <Layer
            id={`far-3-circle-layer`}
            type="fill"
            paint={{
              "fill-color": "#088",
              "fill-opacity": 0.1,
            }}
          />
        </Source>
    );
};

export default StationCircleLayerFSR3;