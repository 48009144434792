import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATE, getStationsAsync } from './api';
import { RootState } from './store';


interface StationsState {
    stations: any[];
    getStationsState : API_STATE;
}

const initialState: StationsState = {
    stations: [],
    getStationsState: API_STATE.IDLE
};

export const getStations = createAsyncThunk(
    'stations/getStations',
    async (thunkAPI) => {
      const response = await getStationsAsync()
      return response
    },
  )

const stationsSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        // Add your slice reducers here
    },
    extraReducers: (builder) => {
        builder.addCase(
            getStations.fulfilled, (state, action) => {
          state.getStationsState = API_STATE.IDLE
          state.stations = action.payload
        })
  
        builder.addCase(
            getStations.pending, (state) => {
            state.getStationsState = API_STATE.LOADING
          }
        )
    }
});

export const { } = stationsSlice.actions;
export const stationsSelector = (state: RootState) => state.station
export default stationsSlice.reducer;