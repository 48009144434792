/**
 * FILEPATH: /Users/avetismuradyan/workspace/urbanics/urbanics-map/urbanics-map-fe/src/store/searchPropertySlice.ts
 * 
 * @description This file contains the implementation of the searchPropertySlice, which is a slice of the Redux store for managing property search.
 * 
 * @packageDocumentation
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction, Slice } from '@reduxjs/toolkit'
import { API_STATE, queryAdjacentPropertiesAsync, queryPropertiesAsync } from './api';
import { RootState } from './store';

/**
 * @interface SearchPropertyState
 * @description Represents the state of the searchPropertySlice in the Redux store.
 * @property {string} propertyQuery - The property query string.
 * @property {string} propertyQueryState - The state of the property query (e.g., "idle", "loading").
 * @property {any[]} propertyResults - The array of property search results.
 */
interface SearchPropertyState {
    propertyQuery: string;
    propertyQueryState: API_STATE;
    propertyResults: any[];
    adjacentPropertyResults: any[];
    adjacentPropertyState: API_STATE;
}

/**
 * @constant initialState
 * @description The initial state of the searchPropertySlice.
 */
const initialState: SearchPropertyState = {
    propertyQuery: "",
    propertyQueryState: API_STATE.IDLE,
    propertyResults: [],
    adjacentPropertyResults:[],
    adjacentPropertyState: API_STATE.IDLE,
}

/**
 * @async
 * @function fetchQueryResults
 * @description An async thunk action creator that fetches the query results from the API.
 * @param {string} query - The property query string.
 * @returns {Promise<any>} The promise that resolves to the query results.
 */
export const queryProperties = createAsyncThunk(
  'searchProperty/queryProperties',
  async (query : string, thunkAPI) => {
    const response = await queryPropertiesAsync(query)
    return response
  },
)

export const fetchAdjacentProperties = createAsyncThunk(
  'searchProperty/fetchAdjacentProperties',
  async (coordinates : any, thunkAPI) => {
    const response = await queryAdjacentPropertiesAsync(coordinates)
    return response
  }
)

/**
 * @constant searchPropertySlice
 * @description The searchPropertySlice slice of the Redux store.
 */
const searchPropertySlice : Slice = createSlice({
    name: 'searchProperty',
    initialState,
    reducers: {
      clearAdjacentProperties: (state, action : PayloadAction<any>) => {
        state.adjacentPropertyResults = []
      },
      dismissAdjacentPropertiesError: (state, action : PayloadAction<any>) => {
        state.adjacentPropertyState = API_STATE.IDLE
      }

    },
    extraReducers: (builder) => {
      builder.addCase(
        queryProperties.fulfilled, (state, action) => {
        state.propertyQueryState = API_STATE.IDLE
        state.propertyResults = action.payload
      })

      builder.addCase(
        queryProperties.pending, (state) => {
          state.propertyQueryState = API_STATE.LOADING
        }
      )

      builder.addCase(
        fetchAdjacentProperties.fulfilled, (state, action) => {
          state.adjacentPropertyState = API_STATE.IDLE
          state.adjacentPropertyResults = action.payload
        })
  
        builder.addCase(
          fetchAdjacentProperties.pending, (state) => {
            state.adjacentPropertyState = API_STATE.LOADING
          }
        )

        builder.addCase(
          fetchAdjacentProperties.rejected, (state) => {
            state.adjacentPropertyState = API_STATE.ERROR
          }
        )
    }
  })

/**
 * @function searchPropertySliceSelector
 * @description A selector function that returns the propertyQuery from the Redux store.
 * @param {RootState} state - The root state of the Redux store.
 * @returns {string} The propertyQuery from the Redux store.
 */
export const searchPropertySliceSelector = (state: RootState) => state.searchProperty;

export const {clearAdjacentProperties, dismissAdjacentPropertiesError} = searchPropertySlice.actions
export default searchPropertySlice.reducer

