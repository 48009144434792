import React, { useEffect } from 'react';
import './Map.css'; // Import component-specific styles if needed
import { propertySliceSelector, setProperty } from '../../store/propertySlice';import { useDispatch, useSelector } from 'react-redux';
import {Map as Mapbox, FillLayer, Source, Layer, useMap} from 'react-map-gl';
import {FeatureCollection} from 'geojson';
import PropertyLayer from './PropertyLayer';
import { AppDispatch } from '../../store/store';
import searchPropertySlice, { clearAdjacentProperties, fetchAdjacentProperties, searchPropertySliceSelector } from '../../store/searchPropertySlice';
import AdjacentLandPropertiesLayer from './AdjacentLandPropertiesLayer';
import AdjacentStrataPropertiesLayer from './AdjacentStrataPropertiesLayer';
import { getStations, stationsSelector } from '../../store/stationsSlice';
import StationMarkerLayer from './StationMarkerLayer';
import StationCircleLayerFSR5 from './StationCircleLayerFSR5';
import StationCircleLayerFSR4 from './StationCircleLayerFSR4';
import StationCircleLayerFSR3 from './StationCircleLayerFSR3';
import mapControlSlice, { mapControlSelector } from '../../store/mapControlSlice';
import MapControlComponent from './mapControlComponent';
import BusStationCircleLayerFSR3 from './BusStationCircleLayerFSR3';
import BusStationCircleLayerFSR4 from './BusStationCircleLayerFSR4';

interface MapProps {

}

const Map: React.FC<MapProps> = ({ }) => {
  // Component logic here

  const dispatch = useDispatch();
  const appDispatch = useDispatch<AppDispatch>();
  const PropertySelector = useSelector(propertySliceSelector);
  const PropertyQuerySelector = useSelector(searchPropertySliceSelector);
  const StationsSelector = useSelector(stationsSelector);
  const MapControlSelector = useSelector(mapControlSelector)

  useEffect(() => {
    if(StationsSelector.stations.length === 0)
      appDispatch(getStations());
  }, []);

  const handleClick = (evt : mapboxgl.MapLayerMouseEvent) => {

    if(evt.features && evt.features[0]){
      let pid = evt.features[0]?.properties?.pid
      let property = PropertyQuerySelector.adjacentPropertyResults.find((result: any) => result.pid === pid)
      dispatch(setProperty(property))
    }

  }


  return (
    <div className="map-container">
        <Mapbox
            mapboxAccessToken="pk.eyJ1IjoiYXZldGlzbXVyYWR5YW4iLCJhIjoiY2xzNDFyd3B3MDRreDJscGpndHowaDZhaiJ9.CDlZKbHvWyDw2CTqlA6jWA"
            mapStyle="mapbox://styles/mapbox/dark-v10"
            reuseMaps={true}
            onMoveEnd={(evt) => {

              if (evt.viewState.zoom >= 18)
                appDispatch(fetchAdjacentProperties({lat : evt.viewState.latitude, long : evt.viewState.longitude}));
              else
                dispatch(clearAdjacentProperties({}) as any);
            }}
            onMoveStart={evt => {dispatch(clearAdjacentProperties({}) as any)}}
            initialViewState={{
              longitude: -123.14226145984392,
              latitude: 49.26370879317324,
              zoom: 12
            }}
            interactiveLayerIds={['polygon-property', 'polygon-adjacent-strata', 'polygon-adjacent-land']}
            onClick={handleClick}
          >
            
            {MapControlSelector.showStations && StationsSelector.stations.length > 0 && StationsSelector.stations.map((station: any) => <StationMarkerLayer station={station} />)}
            {MapControlSelector.showTFR5 && StationsSelector.stations.length > 0 && <StationCircleLayerFSR5 stations={StationsSelector.stations.filter((station) => station.station_type == 'RAPID_TRANSIT_STATION')} />}
            {MapControlSelector.showTFR4 && StationsSelector.stations.length > 0 && <StationCircleLayerFSR4 stations={StationsSelector.stations.filter((station) => station.station_type == 'RAPID_TRANSIT_STATION')} />}
            {MapControlSelector.showTFR3 && StationsSelector.stations.length > 0 && <StationCircleLayerFSR3 stations={StationsSelector.stations.filter((station) => station.station_type == 'RAPID_TRANSIT_STATION')} />}
            {MapControlSelector.showTFR3 && StationsSelector.stations.length > 0 && <BusStationCircleLayerFSR3 stations={StationsSelector.stations.filter((station) => station.station_type == 'BUS_STATION')} />}
            {MapControlSelector.showTFR3 && StationsSelector.stations.length > 0 && <BusStationCircleLayerFSR4 stations={StationsSelector.stations.filter((station) => station.station_type == 'BUS_STATION')} />}
            
            <AdjacentLandPropertiesLayer
              adjacentProperties={PropertyQuerySelector.adjacentPropertyResults}
            />
            {/* <AdjacentStrataPropertiesLayer
              adjacentProperties={PropertyQuerySelector.adjacentPropertyResults}
            /> */}
            <PropertyLayer
              property={PropertySelector.property}
            />
          </Mapbox>
    </div>
  );

}

export default Map;