import { Alert, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import searchPropertySlice, { dismissAdjacentPropertiesError, searchPropertySliceSelector } from '../../store/searchPropertySlice';
import { API_STATE } from '../../store/api';

interface MapLoaderComponentProps {}

const MapLoaderComponent: React.FC<MapLoaderComponentProps> = () => {
    const SearchPropertySliceSelector = useSelector(searchPropertySliceSelector);
    const dispatch = useDispatch();

    return (
        <Grid container sx={{width:'100%', top:'50%', position:'absolute', zIndex:100}} justifyContent='center'>
            {SearchPropertySliceSelector.adjacentPropertyState == API_STATE.LOADING && <CircularProgress size='70px'/>}
            <Divider sx={{width:1}}/>
            {SearchPropertySliceSelector.adjacentPropertyState == API_STATE.LOADING && 
                <Typography variant='body1' color='primary'>
                    Map Loading...
                </Typography>
            }
            {SearchPropertySliceSelector.adjacentPropertyState == API_STATE.ERROR && 
            <Alert variant="filled" severity="error" onClose={() => {dispatch(dismissAdjacentPropertiesError({}) as any)}}>
                There was an error loading adjacent properties.
            </Alert>}
        </Grid>
    );
};

export default MapLoaderComponent;