import React from 'react';
import './SchedulingForm.css'; 
import { propertySliceSelector } from '../../store/propertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { scheduleConsultation, schedulingFormSliceSelector, schedulingFormState, setEmail, setFirstName, setHidden, setLastName, setPhoneNumber } from '../../store/schedulingSlice';
import { Alert, Button, TextField } from '@mui/material';
import { AppDispatch } from '../../store/store';

const SchedulingForm: React.FC = () => {

    const dispatch = useDispatch();
    const appDispatch = useDispatch<AppDispatch>();
    const schedulingFormSelector = useSelector(schedulingFormSliceSelector) as schedulingFormState;
    const propertySelector = useSelector(propertySliceSelector)

    const property = propertySelector.property;

    let isVisible = schedulingFormSelector.isVisible;
    let firstName = schedulingFormSelector.firstName;
    let lastName = schedulingFormSelector.lastName;
    let email = schedulingFormSelector.email;
    let phoneNumber = schedulingFormSelector.phoneNumber;

    let handleFirstNameChange = (value : string) : void => {
        dispatch(setFirstName(value));
    }

    let handleLastNameChange = (value: string): void => {
        dispatch(setLastName(value));
    }

    let handleEmailChange = (value: string): void => {
        if(value === "")
            dispatch(setEmail({value: value, isValid: true}));

        let  serchfind = true

        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        serchfind = regexp.test(value);

        dispatch(setEmail({value: value, isValid: serchfind}));
    }

    let handlePhoneNumberChange = (value: string): void => {
        if(value === "")
            dispatch(setEmail({value: value, isValid: true}));

        let  serchfind = true

        let regexp = new RegExp(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/);
        serchfind = regexp.test(value);

        dispatch(setPhoneNumber({value: value, isValid: serchfind}));
    }

    return (
        <div>
            {isVisible &&
            <div className="scheduling-screen">
                <div className="scheduling-container-outer">
                    <div className="scheduling-container-inner">
                        <Container>
                            <Row>
                                <h2>Schedule a Consultation with Urbanics</h2>
                            </Row>
                            <Row>
                                <h4>{property.from_civic_number && property.from_civic_number + "-"}{property.to_civic_number} {property.street_name}, {property.property_postal_code}</h4>
                            </Row>
                            <Row>
                                {schedulingFormSelector.submissionState === 'fulfilled' &&
                                    <Alert className="alert" severity="success">Form Submitted!</Alert>}
                                {schedulingFormSelector.submissionState === 'rejected' &&
                                <Alert className="alert" severity="error">Submission Error</Alert>}
                            </Row>
                            <Row className='show-on-mobile'>
                                <br/>
                            </Row>
                            <Row>
                                <Col className='hide-on-mobile'></Col>
                                <Col>
                                    <TextField fullWidth label="First Name" variant="outlined" size="small" value={firstName} onChange={(e) => {handleFirstNameChange(e.target.value)}}/>
                                </Col>
                                <Col>
                                    <TextField fullWidth label="Last Name" variant="outlined" size="small" value={lastName} onChange={(e) => {handleLastNameChange(e.target.value)}}/>
                                </Col>
                                
                            </Row>
                            <Row>
                                <br/>
                            </Row>
                            <Row>
                                <Col className='hide-on-mobile'></Col>
                                <Col>
                                    <TextField type="email" fullWidth error={!email.isValid} label="Email" variant="outlined" size="small" value={email.value} onChange={(e) => {handleEmailChange(e.target.value)}}/>
                                </Col>
                                <Col></Col>
                                
                            </Row>
                            <Row>
                                <br/>
                            </Row>
                            <Row>
                                <Col className='hide-on-mobile'></Col>
                                <Col>
                                    <TextField fullWidth type="phone" error={!phoneNumber.isValid} label="Phone Number" variant="outlined" size="small" value={phoneNumber.value} onChange={(e) => {handlePhoneNumberChange(e.target.value)}}/>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <br/>
                            </Row>
                            <Row>
                                <Col className='hide-on-mobile'></Col>
                                <Col>
                                    <Button 
                                        variant="contained" 
                                        disabled={!(email.isValid && phoneNumber.isValid) || !(firstName.length > 0 && lastName.length > 0)}
                                        onClick={() => {
                                            appDispatch(scheduleConsultation({
                                                firstName: firstName,
                                                lastName: lastName,
                                                email: email.value,
                                                phoneNumber: phoneNumber.value,
                                                pid: property.pid,
                                                createdAt: new Date().toLocaleDateString("en-CA")
                                            }));
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    
                                    <Button 
                                        style = {{marginLeft: "1em"}}
                                        variant="outlined"
                                        onClick={() => dispatch(setHidden())}
                                    >Close</Button>
                                </Col>
                                <Col className='hide-on-mobile'></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default SchedulingForm;