import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { scheduleConsultationAsync } from './api'

export interface schedulingFormState {
    isVisible : boolean;
    submissionState: string;
    firstName : string;
    lastName : string;
    email : any;
    phoneNumber : any;
}

const initialState: schedulingFormState = {
    isVisible : false,
    submissionState: "unsubmitted",
    firstName : '',
    lastName : '',
    email : {
        value:'',
        isValid:true,
    },
    phoneNumber:{
        value:'',
        isValid:true,
    },
};

export const scheduleConsultation = createAsyncThunk(
    'schedulingForm/scheduleConsultation',
    async (data : any, thunkAPI) => {
      console.log('data', data)
      const response = await scheduleConsultationAsync(data)
      return response
    },
  )

export const schedulingFormSlice = createSlice({
    name: 'schedulingForm',
    initialState,
    reducers: {
        setVisible: (state: schedulingFormState) => {
            state.isVisible = true;
        },
        setHidden: (state: schedulingFormState) => {
            state.isVisible = false;
            state.submissionState="unsubmitted";
            state.firstName = '';
            state.lastName = '';
            state.email = {
                value:'',
                isValid:true,
            };
            state.phoneNumber={
                value:'',
                isValid:true,
            };
        },
        setFirstName(state: schedulingFormState, action: PayloadAction<string>) {
            state.firstName = action.payload;
        },
        setLastName(state: schedulingFormState, action: PayloadAction<string>) {
            state.lastName = action.payload;
        },
        setEmail(state: schedulingFormState, action: PayloadAction<any>) {
            state.email = action.payload;
        },
        setPhoneNumber(state: schedulingFormState, action: PayloadAction<any>) {
            state.phoneNumber = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            scheduleConsultation.fulfilled, (state, action) => {
          // Add user to the state array
          state.submissionState = "fulfilled"
        })

        builder.addCase(
            scheduleConsultation.pending, (state) => {
              console.log('state pending')
            }
          )

        builder.addCase(
            scheduleConsultation.rejected, (state, action) => {
              console.log('state rejected')
              state.submissionState = "rejected"
            }
          )
      }
});

export const { setVisible, setHidden, setFirstName, setLastName, setEmail, setPhoneNumber } = schedulingFormSlice.actions;
export const schedulingFormSliceSelector = (state: RootState) => state.schedulingForm;
export default schedulingFormSlice.reducer;