import React from 'react';
import { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'

interface StationMarkerLayerProps {
    station : any;
}

const StationMarkerLayer: React.FC<StationMarkerLayerProps> = ({station}) => {
    return (
        <Marker longitude={station.long} latitude={station.lat} anchor='center'>
            {station.station_type == 'RAPID_TRANSIT_STATION' && <img src={require("../../assets/metro.png")} alt="station" height="15px"/>}
            {station.station_type == 'BUS_STATION' && <img src={require("../../assets/bus.png")} alt="station" height="20px"/>}
        </Marker>
    );
};

export default StationMarkerLayer;