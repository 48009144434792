import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import schedulingFormSlice from './schedulingSlice'
import thunk from "redux-thunk";
import stationsSlice from './stationsSlice';
import searchPropertySlice from './searchPropertySlice';
import propertySlice from './propertySlice';
import mapControlSlice from './mapControlSlice';

const store = configureStore({
  reducer: {
    searchProperty : searchPropertySlice,
    property: propertySlice,
    schedulingForm: schedulingFormSlice,
    station: stationsSlice,
    mapControl : mapControlSlice
  },
    
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export default store;