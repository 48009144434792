import { FeatureCollection, Feature, Geometry, GeoJsonProperties } from "geojson";
import { useEffect } from "react";
import {FillLayer, Layer, Source, useMap } from "react-map-gl";
import { fetchAdjacentProperties } from "../../store/searchPropertySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

interface AdjacentLandPropertiesLayerProps {
    adjacentProperties: any[];
}



const AdjacentLandPropertiesLayer: React.FC<AdjacentLandPropertiesLayerProps> = ({ adjacentProperties }) => {

    const appDispatch = useDispatch<AppDispatch>();

    let {current : map} = useMap();


    const geojson = () : FeatureCollection => {


      let zoom = map?.getZoom()

      if(!adjacentProperties || (zoom && zoom < 14))
          return {
              type: 'FeatureCollection',
              features: []
          };

      let features : Feature<Geometry, GeoJsonProperties>[] = []

      let renderedAdjacentProperties = adjacentProperties.filter((property)=> property.legal_type == 'LAND')

      renderedAdjacentProperties.map((property)=>{
        let coordinates = JSON.parse(property.geom)
        
        features.push(
          {
              type: 'Feature', 
              geometry: coordinates,
              properties: {
                pid : property.pid
              }
          }
        )
      })

      return {
        type: 'FeatureCollection',
        features: features
      };
    }

    const layerStyle : FillLayer = {
        id: 'polygon-adjacent-land',
        type: 'fill',
        paint: {
          'fill-antialias': true,
          'fill-color': '#051f1e',
          'fill-outline-color': '#127671',
          'fill-opacity': 1
        }
      } 

      return (
        <Source id='polygon-adjacent-land' type="geojson" data={geojson()}>
            <Layer {...layerStyle} />
        </Source>
    );
}

export default AdjacentLandPropertiesLayer;