import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapControlSelector, toggleShowStation, toggleShowTFR3, toggleShowTFR4, toggleShowTFR5 } from '../../store/mapControlSlice';
import DirectionsSubwayIcon from '@mui/icons-material/DirectionsSubway';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface MapControlComponentProps {}

const MapControlComponent: React.FC<MapControlComponentProps> = () => {

    const dispatch = useDispatch();

    const MapControlSelector = useSelector(mapControlSelector)

    return (
        <Grid sx={{position : 'absolute', width : '100px', height : '100px', zIndex : 4, left : '10px', top : '80px'}} container justifyContent='left'>
            <Button 
            variant={MapControlSelector.showStations ? 'contained' : 'outlined'} 
            onClick={() => dispatch(toggleShowStation())} 
            startIcon={<DirectionsSubwayIcon/>} 
            size='small'
            sx={{width : '100%'}}
            >
                Stations
            </Button>
            <Divider sx={{width:'0px'}}/>
            <Button 
            variant={MapControlSelector.showTFR5 ? 'contained' : 'outlined'} 
            onClick={() => dispatch(toggleShowTFR5())} 
            startIcon={<KeyboardDoubleArrowUpIcon/>} 
            sx={{width : '100%', marginTop:'10px'}}
            size='small'>
                FAR 5
            </Button>
            <Button 
            variant={MapControlSelector.showTFR4 ? 'contained' : 'outlined'} 
            onClick={() => dispatch(toggleShowTFR4())} 
            startIcon={<KeyboardArrowUpIcon/>} 
            sx={{width : '100%', marginTop:'10px'}}
            size='small'>
                FAR 4
            </Button>
            <Button 
            variant={MapControlSelector.showTFR3 ? 'contained' : 'outlined'} 
            onClick={() => dispatch(toggleShowTFR3())} 
            startIcon={<ArrowDropUpIcon/>} 
            sx={{width : '100%', marginTop:'10px'}}
            size='small'>
                FAR 3
            </Button>
        </Grid>
    );
};

export default MapControlComponent;