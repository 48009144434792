import axios from 'axios';

console.log(' process.env.REACT_APP_API',  process.env)
const BASE_URL = process.env.REACT_APP_API;

const userAPI = axios.create({
    baseURL: BASE_URL,
});

export const enum API_STATE {
    LOADING = 'loading',
    IDLE = 'idle',
    ERROR = 'error',
    SUCCESS = 'success'
}

export const queryPropertiesAsync = async (query: string) => {
    try {
        const response = await userAPI.get(`/properties/query/${query}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to query properties');
    }
};

export const queryAdjacentPropertiesAsync = async (coordinates: any) => {
    try {
        const response = await userAPI.get(`/properties/latlong_query/${coordinates.lat}&${coordinates.long}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to query adjacent properties');
    }
};

export const scheduleConsultationAsync = async (data: any) => {
    try {
        const response = await userAPI.post('/schedule', data);
        return response.data;
    } catch (error) {
        throw new Error('Failed to post consultation');
    }
}

export const getStationsAsync = async () => {
    try {
        const response = await userAPI.get('/station');
        return response.data;
    } catch (error) {
        throw new Error('Failed to query stations');
    }
}