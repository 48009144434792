import React from 'react';
import './PropCard.css'; // Import component-specific styles if needed
import { Button } from '@mui/material';
import { propertySliceSelector } from '../../store/propertySlice';
import { setVisible } from '../../store/schedulingSlice';
import { useDispatch, useSelector } from 'react-redux';
import {Col, Image, Row} from 'react-bootstrap';
import { AppDispatch } from '../../store/store';

interface PropCardProps {
}

const PropCard: React.FC<PropCardProps> = () => {
  // Component logic here

    const dispatch = useDispatch();

    const selector = useSelector(propertySliceSelector); // Specify the RootState type for the useSelector hook
    let property = selector.property;

    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      });

    let landValue = ''
    let improvementValue = ''
    let totalValue = ''
    let hasAppreciation = false
    let projectedValue = ''
    let appreciation = ''

    if(property){
        landValue = formatter.format(property.current_land_value)
        improvementValue = formatter.format(property.current_improvement_value)
        totalValue = formatter.format(property.total_value)

        if(property.psf_analysis_price && property.current_land_value){
            hasAppreciation = property.psf_analysis_price > property.current_land_value
            projectedValue = formatter.format(property.psf_analysis_price)
            appreciation = formatter.format(property.psf_analysis_price - property.current_land_value)
        }
    }
    

  return (
    <div>
        {property && 
        <div className="propcard-container">
            
            <div>
                <div className="title-container">
                    <p className='align-text-bottom'>
                        {property.from_civic_number && property.from_civic_number + "-"}{property.to_civic_number} {property.street_name}, {property.city} 
                        <br/>
                        {property.property_postal_code}
                    </p>
                </div>
                {hasAppreciation &&
                <div className='appreciation-container'>
                    <Row>
                        <Col sm={3} className='appreciation-container-col'>
                            <Image 
                                height="80"
                                src={require("../../assets/business-report.png")} 
                                alt="Urbanics Logo" />
                        </Col>
                        <Col sm={9} className='appreciation-container-col'>
                            <p>Your property stands to appreciate by</p>
                            <h4>{appreciation}</h4>
                            <p>Schedule a consultation with us today to unlock this value!</p>
                        </Col>
                    </Row>
                </div>
                }
                <div style={{height: "450px;", margin:"1em"}}>
                    <table className='propcard-table'>
                        <tr>
                            <th>Legal Type</th>
                            <td>{property.legal_type}</td>
                        </tr>
                        <tr>
                            <th>Year Built</th>
                            <td>{property.year_built}</td>
                        </tr>
                        <tr>
                            <th>Assessment Year</th>
                            <td>{property.tax_assessment_year}</td>
                        </tr>
                        <tr>
                            <th>Land Value</th>
                            <td>{landValue}</td>
                        </tr>
                        <tr>
                            <th>Improvement Value</th>
                            <td>{improvementValue}</td>
                        </tr>
                        <tr>
                            <th>Total Value</th>
                            <td>{totalValue}</td>
                        </tr>
                        {property.far &&
                            <tr>
                                <th>Rezoned to</th>
                                <td>{property.far} FAR</td>
                            </tr>
                        }
                        {property.psf_analysis_price &&
                            <tr>
                                <th>Projected Land Value</th>
                                <td>{projectedValue}
                                {hasAppreciation &&
                                 <span className="td-profit"> +{appreciation}</span>
                                }
                                </td>
                            </tr>
                        }

                    </table>'
                    <br/>
                    {property.legal_type == "STRATA" && <p>Consultation Unavailable for Strata</p>}
                    {property.legal_type == "LAND" && 
                    <Button 
                        variant="outlined" 
                        onClick={() => {dispatch(setVisible())}} // Call the setVisible action creator
                    >
                        Schedule Consultation
                    </Button>}
                </div>
                </div>

            
        </div>
        }
    </div>
        
  );
};

export default PropCard;