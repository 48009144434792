import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface MapControlState {
    showStations : boolean;
    showTFR5 : boolean;
    showTFR4 : boolean;
    showTFR3 : boolean;
}

const initialState: MapControlState = {
    showStations: true,
    showTFR5: true,
    showTFR4: true,
    showTFR3: true
};


const mapControlSlice = createSlice({
    name: 'mapControl',
    initialState: initialState,
    reducers: {
        toggleShowStation: (state) => {
            state.showStations = !state.showStations;
        },
        toggleShowTFR5: (state) => {
            state.showTFR5 = !state.showTFR5;
        },
        toggleShowTFR4: (state) => {
            state.showTFR4 = !state.showTFR4;
        },
        toggleShowTFR3: (state) => {
            state.showTFR3 = !state.showTFR3;
        },
    },
});

export const mapControlSelector = (state: RootState) => state.mapControl
export const { toggleShowStation, toggleShowTFR5, toggleShowTFR4, toggleShowTFR3 } = mapControlSlice.actions;
export default mapControlSlice.reducer;