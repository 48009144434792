import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

/**
 * Represents the state of the property in the Redux store.
 */
interface propertyState {
    property: any;
}

/**
 * The initial state of the property slice.
 */
const initialState: propertyState = {
    property: null
}

/**
 * The property slice of the Redux store.
 */
const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        /**
         * Sets the property value in the state.
         * @param state - The current state.
         * @param action - The payload action containing the new property value.
         */
        setProperty: (state: propertyState, action: PayloadAction<any>) => {
            console.log('action.payload', action.payload)
            state.property = action.payload
        }
    }
})

export const { setProperty } = propertySlice.actions

/**
 * Selects the property slice from the root state.
 * @param state - The root state of the Redux store.
 * @returns The property slice from the root state.
 */
export const propertySliceSelector = (state: RootState) => state.property

export default propertySlice.reducer
