import { FeatureCollection } from "geojson";
import { useEffect } from "react";
import {FillLayer, Layer, Source, useMap } from "react-map-gl";
import { fetchAdjacentProperties } from "../../store/searchPropertySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

interface PropertyLayerProps {
    property: any;
}



const PropertyLayer: React.FC<PropertyLayerProps> = ({ property }) => {

    const appDispatch = useDispatch<AppDispatch>();

    let {current : map} = useMap();

    const geojson = () : FeatureCollection => {

        if(!property)
            return {
                type: 'FeatureCollection',
                features: []
            };


        let coordinates = JSON.parse(property.geom)
        return {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature', 
                    geometry: coordinates,
                    properties: {}
                }
            ]    
          };
    }

    const layerStyle: FillLayer = {
        id: 'polygon-property',
        type: 'fill',
        paint: {
          'fill-antialias': true,
          'fill-color': '#ecac00',
          'fill-outline-color': '#ecac00',
          'fill-opacity': 1
        }
      };


    const flyTo = () : void => {

        if(!property)
          return;

        map?.flyTo({
            center : [property.long, property.lat],
            zoom: 18
        });
      }
    
      useEffect(() => {
        console.log('geojson0', geojson)
        flyTo();

        if (property)
          appDispatch(fetchAdjacentProperties({
            lat : property.lat, 
            long : property.long
          }))
      }, [property])

    return (
        <Source type="geojson" data={geojson()}>
            <Layer {...layerStyle} />
        </Source>
    );
}

export default PropertyLayer;