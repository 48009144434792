import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import store from './store/store';
import NavbarComponent from './components/NavbarComponent/NavbarComponent';
import Map from './components/Map/Map';
import PropCard from './components/PropCard/PropCard';
import SchedulingForm from './components/SchedulingForm/SchedulingForm';
import MapLoaderComponent from './components/Map/MapLoaderComponent';
import MapControlComponent from './components/Map/mapControlComponent';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <NavbarComponent />
        <MapLoaderComponent/>
        <Map/>
        <MapControlComponent/>
        <PropCard/>
        <SchedulingForm/>

      </div>
    </Provider>
  );
}

export default App;
