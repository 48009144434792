import React, { useEffect } from 'react';
import './NavbarComponent.css'; // Import component-specific styles if needed
import AsyncSelect from 'react-select/async'
import { queryProperties, searchPropertySliceSelector } from '../../store/searchPropertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { InputActionMeta } from 'react-select';
import { resolve } from 'path';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {setProperty} from '../../store/propertySlice';
import { AppBar, Box, Container, Grid, Toolbar, Tooltip } from '@mui/material';

interface NavbarComponentProps {
  
}

/**
 * NavbarComponent is a functional component that represents the navigation bar of the application.
 * It displays a logo, a subtitle, and a search bar to search for properties by address.
 */
const NavbarComponent: React.FC<NavbarComponentProps> = () => {
  // Component logic here

  const dispatch = useDispatch();
  const appDispatch = useDispatch<AppDispatch>()
  const stateSearchProperty = useSelector(searchPropertySliceSelector)


  
  let options : any[] = stateSearchProperty.propertyResults.map((result: any) => {

    let aptNumber = ""
    if(result.from_civic_number)
      aptNumber = result.from_civic_number + "-"

    let formattedAddress : String = (aptNumber || '') + result.to_civic_number + " " + result.street_name + " " + (result.property_postal_code || '')

    return {
      id: result.pid, 
      label: formattedAddress
    }

  })

  useEffect(()=>{console.log('options', options.length)}, options)
  
  /**
   * Handles the change event of the Autocomplete component.
   * If the reason for the change is 'selectOption', it sets the selected property as the current property.
   * @param event - The change event object.
   * @param value - The selected value from the Autocomplete component.
   * @param reason - The reason for the change.
   */
  function handleOnChange(event: any, value: any, reason: AutocompleteChangeReason) {
    if (reason === 'selectOption') {
      let property = stateSearchProperty.propertyResults.find((result: any) => result.pid === value.id)

      dispatch(setProperty(property))
    }
    if (reason == 'clear'){
      console.log('clear')
    }
  }

  return (
    <AppBar sx={{ backgroundColor: '#fff'}}>
      <Toolbar variant='regular'>

        <Box
            className='logo-box-desktop'
            component="img"
            alt="The house from the offer."
            src={require('../../assets/urbanics.png')}
          />


          <Box
            className='logo-box-mobile'
            component="img"
            alt="The house from the offer."
            src={require('../../assets/urbanics-mobile.png')}
          />

          <Box className='property-search-box'>
            <Tooltip title="Search Properties in the City of Vancouver. Other Lower Mainland cities coming soon..." placement="left">
              <Autocomplete
                disablePortal
                options={options}
                filterOptions={(options) => options}
                loading={stateSearchProperty.propertyQueryState === "loading"}
                noOptionsText='No Properties'
                size="small"
                renderInput={(params) => <TextField {...params} label="Search Property by Address" />}
                onInputChange={(event, newValue) => appDispatch(queryProperties(newValue))}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={handleOnChange}
              />
            </Tooltip>
          </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarComponent;